document.addEventListener('DOMContentLoaded', () => {
    const sidenav = document.getElementById('sidenav');
    const header = document.querySelectorAll('[role="main"]')[0];
    const headerOffset = header.offsetHeight;
    let topSidenavOffset = sidenav.offsetTop - 33;

    function checkScroll() {
        if (window.innerWidth > 1355) {
            if (window.scrollY > topSidenavOffset) {
                sidenav.classList.add('fixed');
            } else {
                sidenav.classList.remove('fixed');
            }
        } else {
            sidenav.classList.remove('fixed');
        }
    }

    window.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', () => {
        topSidenavOffset = sidenav.offsetTop - 33;
        checkScroll();
    });
});
